<template lang="pug">
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { extend } from 'vee-validate';
import convertCurrencyToSymbol from '@/utils/convertCurrencyToSymbol';


extend('minDeposit', value => {
    return value >= 5;
});
extend('maxDeposit', value => {
    return value <= 20000;
});

export default {
  name: 'Deposit',
  data: () => ({
    currencyFormat: convertCurrencyToSymbol,
    d_cards: [
      {
        id: 'visa',
        image: require('@/assets/images/personal/payments/visa.svg'),
      },
      {
        id: 'master',
        image: require('@/assets/images/personal/payments/master.svg'),
      },
      {
        id: 'maestro',
        image: require('@/assets/images/personal/payments/maestro.svg'),
      },
      {
        id: 'cirrus',
        image: require('@/assets/images/personal/payments/cirrus.svg'),
      },
      {
        id: 'ae',
        image: require('@/assets/images/personal/payments/american_express.svg'),
      },
      {
        id: 'mir',
        image: require('@/assets/images/personal/payments/mir.svg'),
      },
    ],
    d_crypto: [
      {
        id: 'btc',
        image: require('@/assets/images/personal/payments/btc.svg'),
      },
      {
        id: 'etc',
        image: require('@/assets/images/personal/payments/etc.svg'),
      },
      {
        id: 'eth',
        image: require('@/assets/images/personal/payments/eth.svg'),
      },
      {
        id: 'usdc',
        image: require('@/assets/images/personal/payments/usdc.svg'),
      },
      {
        id: 'bnb',
        image: require('@/assets/images/personal/payments/bnb.svg'),
      },
      {
        id: 'usdt',
        image: require('@/assets/images/personal/payments/usdt.svg'),
      },
    ],
    d_e_wallets: [
      {
        id: 'webmoney',
        image: require('@/assets/images/personal/payments/webmoney.svg'),
      },
      {
        id: 'yadengi',
        image: require('@/assets/images/personal/payments/yandex.svg'),
      },
    ],
    depositValue: null,
    isDepositClicked: false,
    isCompanyActive: false,
    isCardsActive: false,
    isCryptoActive: false,
    hideDepositMethods: false,
  }),
  computed: {
    ...mapGetters({
      currentLanguage: 'user/getLanguage',
      userBalance: 'user/getBalance',
      openSection: 'personal/getOpenSection',
    }),

    amountPlaceholder() {
      return `${this.$t('profile.payments.amountLabel')} ` + this.getCurrency;
    },
    getCurrency(){
      return this.currencyFormat(this.userBalance.currency);
    },
    getMinAmount(){
      return this.isCardsActive ? 5 : (this.isCryptoActive ? 0.001 : 5); //todo get limits from server
    },
    getMaxAmount(){
      return this.isCardsActive ? 20000 : (this.isCryptoActive ? 20000 : 20000); //todo get limits from server
    },
    getAmountRules() {
      return `required|between:${this.getMinAmount},${this.getMaxAmount }`;
          // v => v >= this.getMinAmount || this.$t('profile.payments.minAmountMessage') + ' ' + this.getMinAmount + this.getCurrency,
          // v => v <= this.getMaxAmount || this.$t('profile.payments.maxAmountMessage') + ' ' + this.getMaxAmount + this.getCurrency,
    },
    getWrongAmountMessage(){
      return (this.$t('profile.payments.wrongAmountMessage'))
          .replaceAll('{min}',this.getMinAmount + this.getCurrency)
          .replaceAll('{max}',this.getMaxAmount + this.getCurrency);
    }
  },
  methods: {
    ...mapActions({
      doPay: 'pay/doPay',
    }),
    ...mapMutations({
      SET_OPEN_PAGE: 'main/SET_OPEN_PAGE',
    }),
    async callPaymentPolicy() {
      this.SET_OPEN_PAGE('paymentPolicy');
      this.$router.push('/payment-policy');
      window.scrollTo(0, 0);
    },
    async handleDeposit() {
      this.isDepositClicked = true;
      let source = null;
      if (this.isCardsActive) {
        source = 'card';
      } else if (this.isCryptoActive) {
        source = 'crypto';
      }
      await this.doPay({type: 'deposit', source: source, pan: null, amount: this.depositValue});
    },
    handleMethod(payMethod) {
      this.depositValue = null;
      this.hideDepositMethods = true;

      if (payMethod === 'cards') {
        this.isCardsActive = true;
      } else if (payMethod === 'crypto') {
        this.isCryptoActive = true;
      }
      window.scrollTo(0, 0);
    },
    listenTextInput(data) {
      if (data) {
        switch(data.name) {
          case 'summ': {
            this.depositValue = data.value.trim();
            return;
          }
        }
      }
    },
  },
  watch: {
    isDepositClicked(newValue) {
      if (newValue) {
        setTimeout(() => {
          this.isDepositClicked = false;
        }, 300);
      }
    },
    openSection(newSection, oldSection) {
      if (newSection !== 'deposit' && oldSection === 'deposit') {
        this.depositValue = null;
        this.hideDepositMethods = false;
        this.isCardsActive = false;
        this.isCryptoActive = false;
        this.$refs.depositPayment.reset();
      }
      else if (newSection === 'deposit') {
        if (this.$metrika) {
          this.$metrika.reachGoal('depositOpen');
        }
      }
    },
  },
};
</script>

<style lang="scss">
</style>